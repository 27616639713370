import React from 'react';
import './App.css';
import Header from './Header';
import OnboardingWidget from './OnboardingWidget';
import { Route } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { useHistory } from 'react-router-dom';

const oktaAuth = new OktaAuth({
  issuer: 'https://miamilakesautomall.oktapreview.com/',
  clientId: '0oacdem8yuGux4n2V1d7',
  redirectUri: window.location.origin + '/callback'
});

const App = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  const onAuthRequired = function() {
   oktaAuth.signInWithRedirect();
  }

  return (
    <div className="App">
      <div className="page" style={{height:'100%'}}>
        <div className="content" style={{height:'80%'}} >
          <Security oktaAuth={oktaAuth}
                    restoreOriginalUri={restoreOriginalUri}
                    onAuthRequired={onAuthRequired}>
            <Header/>
            <SecureRoute path='/' exact={true} component={OnboardingWidget}/>
            <Route path='/callback' component={LoginCallback}/>
          </Security>
        </div>
      </div>
    </div>
  );
}

export default App;
